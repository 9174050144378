import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import AbstractComponent from "../../../AbstractComponent";
import Button from "../../../layout/button/Button";
import ButtonEscolher from "../../../layout/button/ButtonEscolher";
import ModalAlerta from "../../../layout/modal/ModalAlerta";
import ModalTitle from "../../../layout/modal/ModalTitle";
import OpcaoEntregaEndereco from "./OpcaoEntregaEndereco";

import CarrinhoService from "../../../../service/CarrinhoService";
import AutenticarService from "../../../../service/AutenticarService";

import AtivoEnum from "../../../../helper/enumerador/AtivoEnum";
import TipoEnderecoEnum from "../../../../helper/enumerador/TipoEnderecoEnum";

import { moeda } from "../../../../helper/Formatar";
import { formataEndereco } from "../../../../helper/Formatar";
import { buscaConfiguracao } from "../../../../helper/Validar";

import "../../../layout/modal/Modal.css";

class OpcaoEntregaModal extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,

      endereco: false,

      novo: false,

      passo: 0,

      active: -1,

      erro: "",

      titulo: "OPÇÃO DE ENTREGA",

      item: [],
    };

    this.titulos = ["OPÇÃO DE ENTREGA", "LISTA DE ENDERECOS", "ENDEREÇO"];
  }

  gravaOpcaoEntrega(item, i) {
    this.setState({
      preload: true,
      active: i,
      endereco: false,
    });

    let form = {};

    form.idCarrinho = AutenticarService.getIdCarrinho();
    form.destinatario = this.props.cliente.destinatario;
    form.cep = this.props.cliente.cep;
    form.logradouro = this.props.cliente.logradouro;
    form.endereco = this.props.cliente.endereco;
    form.numero = this.props.cliente.numero;
    form.complemento = this.props.cliente.complemento;
    form.bairro = this.props.cliente.bairro;
    form.cidade = this.props.cliente.cidade;
    form.uf = this.props.cliente.uf;

    form.tipo_endereco = this.props.cliente.tipo_endereco;
    form.codigo_municipio = this.props.cliente.codigo_municipio;
    form.ponto_de_interesse = this.props.cliente.ponto_de_interesse;
    form.ponto_de_interesse_manual =
      this.props.cliente.ponto_de_interesse_manual;
    form.ponto_de_interesse_descricao =
      this.props.cliente.ponto_de_interesse_descricao;

    form.entregaId = item.id;
    form.entregaDescricao = item.descricao;
    form.entregaPerguntaFormaPagamento = item.pergunta_forma_pagamento;
    form.entregaTemTaxaEntrega = item.tem_taxa_entrega;

    CarrinhoService.gravarOpcaoEntrega(form)
      .then((response) => {
        this.setState({
          preload: false,
          active: -1,
        });

        this.props.onUpdateCart(response.data);
        this.fechar();
      })
      .catch((erro) => {
        this.setState({
          preload: false,
          active: -1,
          erro: this.trataErro(erro),
        });
      });
  }

  onClick(item, i) {
    if (item.tem_taxa_entrega === AtivoEnum.SIM) {
      this.setState({
        endereco: true,
        active: i,
        item: item,
      });

      return;
    }

    this.gravaOpcaoEntrega(item, i);
  }

  onEditClick() {
    this.gravaOpcaoEntrega(this.state.item, this.state.active);
  }

  style(i) {
    if (!this.state.preload || this.state.active !== i) return {};

    return {
      textAlign: "center",
      display: "inherit !important",
    };
  }

  classNameButton(i) {
    if (!this.state.preload || this.state.active !== i) return "";

    return "preload";
  }

  fechar() {
    if (this.state.passo > 0) {
      this.setState({
        passo: this.state.passo - 1,
        titulo: this.titulos[this.state.passo - 1],
      });

      if (this.state.passo - 1 < 2) this.setState({ novo: false });

      return;
    }

    this.setState({
      endereco: false,
    });

    this.props.fechar();
  }

  showPoI() {
    if (this.props.cliente.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)
      return;

    return (
      <Fragment>
        <div className="AddressBox-address">
          <label>Ponto de Interesse:</label>
          &nbsp;
          {this.props.cliente.ponto_de_interesse}
        </div>

        <div className="AddressBox-address">
          <label>Descrição:</label>
          &nbsp;
          {this.props.cliente.ponto_de_interesse_descricao}
        </div>

        <div className="AddressBox-zipcode"></div>
      </Fragment>
    );
  }

  showEndereco() {
    if (this.props.cliente.tipo_endereco === TipoEnderecoEnum.POI.name) return;

    return (
      <Fragment>
        <div className="AddressBox-address">
          {this.props.cliente.cep +
            " - " +
            formataEndereco(
              this.props.cliente.logradouro,
              this.props.cliente.endereco,
              false
            ) +
            ", " +
            this.props.cliente.numero}
        </div>

        <div className="AddressBox-address">
          {(this.props.cliente.complemento !== undefined &&
          this.props.cliente.complemento !== ""
            ? this.props.cliente.complemento + " - "
            : "") +
            this.props.cliente.bairro +
            " - " +
            this.props.cliente.cidade +
            " " +
            this.props.cliente.uf}
        </div>

        <div className="AddressBox-zipcode"></div>
      </Fragment>
    );
  }

  endereco() {
    if (!this.state.endereco) return;

    return (
      <Fragment>
        <div style={{ marginBottom: "5px" }}></div>

        <div
          className="col-xs-12 
                                col-sm-12 
                                col-md-12 
                                col-lg-12
                                AddressBox"
        >
          <div
            className="col-md-12                                 
                                    col-sm-12                                                
                                    col-lg-12                                                
                                    col-xs-12"
          >
            <div className="AddressBox-title">
              <b>{this.props.cliente.destinatario}</b>
            </div>

            {this.showEndereco()}

            {this.showPoI()}

            <a
              className="AddressBox-edit"
              style={{
                cursor: "pointer",
              }}
              onClick={() => this.setState({ passo: this.state.passo + 1 })}
            >
              Alterar Endereço de Entrega
            </a>
          </div>
        </div>

        <div
          className="col-xs-12 
                               col-sm-12 
                               col-md-12 
                               col-lg-12
                               AddressBox"
          style={{
            border: "none",
            marginTop: "10px",
          }}
        >
          <Button
            type="button"
            background={1}
            className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
            onClick={() => this.onEditClick()}
          >
            Continuar
          </Button>
        </div>
      </Fragment>
    );
  }

  classNameDiv(item) {
    if (
      item.tem_taxa_entrega === AtivoEnum.NAO ||
      this.props.carrinho.entrega.valor_pedido_minimo === 0.0
    )
      return;

    return "payment-methods-modal__method-div-div";
  }

  getSubtitulo(id, valor_pedido_minimo) {
    if (id === 1) {
      const enderecoRetirada = buscaConfiguracao(
        this.props.empresa.configuracao,
        "Empresa",
        "Endereço_para_retirada",
        ""
      );

      return enderecoRetirada;
    }

    return "* Compras acima de " + moeda(valor_pedido_minimo);
  }

  showSubtitulo(index, id, valor_pedido_minimo) {
    if (id === 1) {
      const enderecoRetirada = buscaConfiguracao(
        this.props.empresa.configuracao,
        "Empresa",
        "Endereço_para_retirada",
        ""
      );

      return (
        (!this.state.preload || this.state.active !== index) && enderecoRetirada
      );
    }

    return (
      (!this.state.preload || this.state.active !== index) &&
      valor_pedido_minimo > 0.0
    );
  }

  opcaoEntrega() {
    if (this.state.passo > 0) return ;

    return (
      <div style={{ padding: "0px" }}>
        {this.props.opcaoEntrega.map((item, i) => {
          return (
            <Fragment key={i}>
              <ButtonEscolher
                key={i}
                type="button"
                className={this.classNameButton(i)}
                showButton={!this.state.preload || this.state.active !== i}
                texto={item.descricao}
                textoSelecionar="Escolher"
                subtitulo={this.getSubtitulo(item.id, item.valor_pedido_minimo)}
                showSubtitulo={this.showSubtitulo(
                  i,
                  item.id,
                  item.valor_pedido_minimo
                )}
                showPreload={this.state.active === i && this.state.preload}
                onClick={() => this.onClick(item, i)}
              />
            </Fragment>
          );
        })}

        {this.endereco()}
      </div>
    );
  }

  onUpdateEnderecoEntrega(endereco, clienteEndereco) {
    this.setState({ passo: 0 });

    this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco);
  }

  onTitulo(titulo) {
    this.setState({ titulo: titulo });
  }

  onChangeStep(passo) {
    this.setState({ passo: passo });
  }

  onNew(value) {
    this.setState({ novo: value });
  }

  rediEndereco(caminho) {
    window.location.href = caminho;
  }
  
  alterarEndereco() {
    if (this.state.passo === 0) return;
    
    return (
      <OpcaoEntregaEndereco
        cidadesDisponiveis={this.props.cidadesDisponiveis}
        cliente={this.props.cliente}
        empresa={this.props.empresa}
        passo={this.state.passo}
        novo={this.state.novo}
        onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
          this.onUpdateEnderecoEntrega(endereco, clienteEndereco)
        }
        rediEndereco={(caminho) =>this.rediEndereco(caminho)}
        onTitulo={(titulo) => this.onTitulo(titulo)}
        onChangeStep={(passo) => this.onChangeStep(passo)}
        onNew={(value) => this.onNew(value)}
      />
    );
  }

  showIcon() {
    if (this.state.passo === 0) return "down";

    return "left";
  }

  render() {
    return (
      <Fragment>
        <ModalAlerta
          show={this.state.erro !== ""}
          texto={this.state.erro}
          fechar={() => this.setState({ erro: "" })}
        />

        <Modal
          show={this.props.show}
          className={this.props.className}
          style={{ zIndex: 9999 }}
          onHide={() => this.fechar()}
        >
          <ModalTitle
            icon={this.showIcon()}
            header={this.state.titulo}
            fechar={() => this.fechar()}
          />

          <Modal.Body>
            {this.opcaoEntrega()}

            {this.alterarEndereco()}
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

OpcaoEntregaModal.defaultProps = {
  header: "",
  update: false,
  item: [],
  id: 0,
};

export default OpcaoEntregaModal;
