import { getUrl, getUrlBase } from './helper/Url';
  
const config = {};

config.versao = '01.17.006/040';
config.atualizado = '02/04/2025';

console.log('versão ' + config.versao);

config.apiLoginGoogle = '1065042674786-a3at5ova5morc6da3bbl9j0arerlgfod.apps.googleusercontent.com';
config.apiClientIdApple = 'br.inf.avance.appshop.sid';
config.apiRedirectURIApple = 'https://avance-appshop.firebaseapp.com/__/auth/handler';

//apiFacebook teste 404147714057311

config.urlBase = getUrlBase();
config.urlSite = getUrl() + config.urlBase.substring(1, config.urlBase.length);

config.urlBaseApi = process.env.REACT_APP_BACKEND_URL;

if (process.env.NODE_ENV === "production") {        
    config.urlBaseApi = config.urlSite + 'api/';
}

config.urlImgBase = config.urlSite + 'images/';
config.urlImgCliente = config.urlImgBase + 'cliente/';

export default config;
