import React, { Fragment } from "react";
import AbstractComponent from "../../../AbstractComponent";
import ModalQuestion from "../../../layout/modal/ModalQuestion";
import ModalAlerta from "../../../layout/modal/ModalAlerta";
import Preload from "../../../layout/preload/Preload";
import EnderecoCadastro from "../../../cliente/EnderecoCadastro";

import { formataEndereco } from "../../../../helper/Formatar";

import AutenticarService from "../../../../service/AutenticarService";
import EnderecoService from "../../../../service/EnderecoService";
import EmpresaFreteService from "../../../../service/EmpresaFreteService";

import TipoEnderecoEnum from "../../../../helper/enumerador/TipoEnderecoEnum";

import "../../../layout/modal/Modal.css";

class OpcaoEntregaEndereco extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,

      erro: "",

      form: {},

      idApagar: 0,

      modalQuestion: false,

      erroForm: {
        destinatario: "",
        cep: "",
        logradouro: "",
        endereco: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        ponto_de_interesse: "",
        ponto_de_interesse_descricao: "",
      },

      forcaPontoInteresse: false,
    };
  }

  cidadesDisponiveisFrete(codigo) {
    let response = true;
    if (this.props.cidadesDisponiveis) {
      for (let i = 0; i < this.props.cidadesDisponiveis.length; i++) {
        if (codigo == this.props.cidadesDisponiveis[i].codigo_municipio) {
          response = false;
        }
      }
      return response;
    }
  }
  
  onRadioTipoEnderecoClick(tipo_endereco) {
    const form = this.state.form;

    form.tipo_endereco = tipo_endereco;

    this.setState({ form: form });
  }

  onChange(e) {
    const form = this.state.form;
    form[e.target.name] = e.target.value;

    if (e.target.name === "ponto_de_interesse") {
      form["ponto_de_interesse_manual"] = "Sim";

      if (form["tipo_endereco"] === TipoEnderecoEnum.POI.name)
        form["codigo_municipio"] = this.props.empresa.codigo_municipio;
    }

    this.setState({ form: form });
  }

  preencheEndereco(endereco) {
    const form = this.state.form;

    form.logradouro = endereco["logradouro"];
    form.endereco = endereco["endereco"];
    form.bairro = endereco["bairro"];
    form.cidade = endereco["cidade"];
    form.uf = endereco["uf"];
    form.codigo_municipio = endereco["codigo_municipio"];
    form.numero = "";
    form.complemento = "";
    form.ponto_de_interesse = "";
    form.ponto_de_interesse_manual = "Nao";
    form.ponto_de_interesse_descricao = "";

    this.setState({
      form: form,
    });
  }

  onBlurCep(endereco) {
    this.preencheEndereco(endereco);

    EmpresaFreteService.pontoDeInteresseExists(
      this.state.form.tipo_endereco,
      endereco["bairro"]
    )
      .then(() => {
        const form = this.state.form;

        form.ponto_de_interesse = endereco["bairro"];

        this.setState({ form: form });

        if (this.state.form.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)
          this.setState({ forcaPontoInteresse: false });
      })
      .catch(() => {
        this.setState({ forcaPontoInteresse: true });
      });
  }

  onBlurBairro(pontoDeInteresse, forcaPontoInteresse) {
    const form = this.state.form;

    form["ponto_de_interesse"] = pontoDeInteresse;

    this.setState({
      form: form,
      forcaPontoInteresse: forcaPontoInteresse,
    });
  }

  onClick(item) {
    var form = this.state.form;

    form = item.endereco;

    form.id = item.id;
    form.destinatario = item.destinatario;
    form.documentoCliente = AutenticarService.getDocumentoCliente();
    form.padrao = item.padrao;
    form.tipo = item.tipo;
    form.tipo_endereco = item.tipo_endereco;
    form.ponto_de_interesse = item.ponto_de_interesse;
    form.ponto_de_interesse_manual = item.ponto_de_interesse_manual;
    form.ponto_de_interesse_descricao = item.ponto_de_interesse_descricao;

    if (form.ponto_de_interesse === "") form.ponto_de_interesse_manual = "Sim";

    this.setState({
      form: form,
    });

    if (this.props.onChangeStep !== undefined) {
      this.props.onChangeStep(this.props.passo + 1);
      this.props.onNew(true);
      this.onTitulo("ALTERAR ENDEREÇO");

      return;
    }

    this.props.onNew(false, form);
  }

  onUpdateEnderecoEntrega(endereco, clienteEndereco) {
    this.onTitulo("OPÇÃO DE ENTREGA");
    this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco);
  }

  removerEnderecoEntrega(endereco, clienteEndereco) {
    this.onTitulo("OPÇÃO DE ENTREGA");

    this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco);
  }

  deleteEnderecoEntrega() {
    this.setState({ preload: true });

    EnderecoService.deletar(
      AutenticarService.getDocumentoCliente(),
      this.state.idApagar
    )
      .then((resposta) => {
        this.setState({
          preload: false,
          modalQuestion: false,
        });

        this.props.onUpdateEnderecoEntrega(undefined, resposta.data);
      })
      .catch((error) => {
        this.setState({
          preload: false,
          modalQuestion: false,
          erro: this.trataErro(error),
        });
      });
  }

  novoEndereco() {
    var form = this.state.form;    

    form.id = 0;
    form.documentoCliente = AutenticarService.getDocumentoCliente();
    form.destinatario = "";
    form.tipo_endereco = "Bairro";
    form.cep = "";
    form.logradouro = "";
    form.endereco = "";
    form.numero = "";
    form.bairro = "";
    form.cidade = "";
    form.uf = "";
    form.tipo = "";
    form.padrao = 0;
    form.ponto_de_entrega = "";
    form.ponto_de_entrega_manual = "Nao";
    form.ponto_de_interesse_descricao = "";

    this.setState({
      form: form,
    });

    if (this.props.onChangeStep !== undefined) {
      this.props.onChangeStep(this.props.passo + 1);

      if (this.props.onTitulo !== undefined)
        this.props.onTitulo("NOVO ENDEREÇO");
    }

    this.props.onNew(true);
  }

  showPoI(item) {
    if (item.tipo_endereco === TipoEnderecoEnum.BAIRRO.name) return;

    return (
      <Fragment>
        <div className="AddressBox-address">
          <label>Ponto de Interesse:</label>
          &nbsp;
          {item.ponto_de_interesse}
        </div>

        <div className="AddressBox-address">
          <label>Descrição:</label>
          &nbsp;
          {item.ponto_de_interesse_descricao}
        </div>

        <div className="AddressBox-zipcode"></div>
      </Fragment>
    );
  }

  showEndereco(item) {
    if (item.tipo_endereco === TipoEnderecoEnum.POI.name) return;
    return (
      <Fragment>
        <div className="AddressBox-address">
          {item.endereco.cep +
            " - " +
            formataEndereco(
              item.endereco.logradouro,
              item.endereco.endereco,
              false
            ) +
            ", " +
            item.endereco.numero}
        </div>

        <div className="AddressBox-address">
          {(item.endereco.complemento !== undefined &&
          item.endereco.complemento !== ""
            ? item.endereco.complemento + " - "
            : "") +
            item.endereco.bairro +
            " - " +
            item.endereco.cidade +
            " " +
            item.endereco.uf}
        </div>

        <div className="AddressBox-zipcode"></div>
      </Fragment>
    );
  }

  listaEndereco() {
    if (this.props.novo) return;

    return (
      <div className={"container-address " + this.props.className}>
        <Preload exibir={this.state.preload} />
        {/* <span style={{visibility: "hidden"}}>.</span> */}
        <ModalAlerta
          header="Erro"
          show={this.state.erro !== ""}
          texto={this.state.erro}
          fechar={() => this.setState({ erro: "" })}
        />

        <ModalQuestion
          show={this.state.modalQuestion}
          btnSimClick={() => this.deleteEnderecoEntrega()}
          btnNaoClick={() => this.setState({ modalQuestion: false })}
          fechar={() => this.setState({ modalQuestion: false })}
          texto={"Deseja excluir esse endereço ?"}
        />

        <div className={"listaEnderecoContainer " + this.props.className}>
            
          {this.props.cliente.cliente_endereco &&
            this.props.cliente.cliente_endereco.map((item, i) => {
              let disp = this.cidadesDisponiveisFrete(
                item.endereco.codigo_municipio
              );
              if (disp) {
                return;
              }
              return (
                <div
                  className={
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 AddressBox " +
                    (item.padrao === 1 || item.padrao === "1"
                      ? "AddressBox-padrao"
                      : "")
                  }
                  key={i}
                >
                  <div
                    className="col-md-8
                                                col-sm-8
                                                col-lg-8
                                                col-xs-12"
                  >
                    <div className="AddressBox-title">
                      <b>{item.destinatario}</b>
                    </div>

                    {this.showEndereco(item)}

                    {this.showPoI(item)}

                    <a
                      className="AddressBox-edit"
                      onClick={() => this.onClick(item)}
                    >
                      Editar
                    </a>

                    <a
                      className="AddressBox-continue"
                      onClick={() => {
                        this.setState({
                          modalQuestion: true,
                          idApagar: item.id,
                        });
                      }}
                    >
                      Apagar
                    </a>
                  </div>

                  {this.props.escolhaEntrega === undefined && (
                    <div
                      className="col-md-4
                                                col-sm-4
                                                col-lg-4
                                                col-xs-12
                                                text-center
                                                AddressBox-Button"
                    >
                      <button
                        className="AddressBox-continue"
                        onClick={() =>
                          this.onUpdateEnderecoEntrega(item, undefined)
                        }
                      >
                        Entregar aqui
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>

        <div
          className="col-md-12
                               col-sm-12
                               col-lg-12
                               col-xs-12
                               AddressBox"
          style={{
            display: "block",
            height: "auto",
            bottom: "0",
          }}
        >
          <a className="text-center" onClick={() => this.novoEndereco()}>
          {/* <a className="text-center" onClick={() => this.props.rediEndereco(config.urlBase + "enderecos")}> */}
            <div
              className="col-md-12
                                        col-sm-12
                                        col-lg-12
                                        col-xs-12"
            >
              <div
                className="row"
                style={{
                  display: "block",
                }}
              >
                <i
                  className="fa fa-plus icon-addressNew"
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                />
              </div>

              <div
                className="row AddressNew"
                style={{
                  display: "block",
                }}
              >
                Cadastrar novo endereço
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }

  endereco() {
    if (!this.props.novo) return;
    
    return (
      <>
        <EnderecoCadastro        
          form={this.state.form}
          cidadesDisponiveis={this.props.cidadesDisponiveis}
          erroForm={this.state.erroForm}
          empresa={this.props.empresa}
          forcaPontoInteresse={this.state.forcaPontoInteresse}
          onChange={(e) => this.onChange(e)}
          onBlurCep={(e) => this.onBlurCep(e)}
          onBlurBairro={(pontoDeInteresse, forcaPontoInteresse) =>
            this.onBlurBairro(pontoDeInteresse, forcaPontoInteresse)
          }
          onErro={(erro) => this.setState({ erroForm: erro })}
          onChangeStep={() => this.props.onChangeStep(this.props.passo - 1)}
          onNew={() => this.props.onNew(false)}
          onRadioTipoEnderecoClick={(tipo_endereco) =>
            this.onRadioTipoEnderecoClick(tipo_endereco)
          }
          onUpdateEnderecoEntrega={(cliente, clienteEndereco) =>
            this.onUpdateEnderecoEntrega(cliente, clienteEndereco)
          }
        />
      </>
    );
  }

  componentDidMount() {
    this.onTitulo("Lista de Endereços");
  }

  render() {
    return (
      <Fragment>
        {this.listaEndereco()}

        {this.endereco()}

      </Fragment>
    );
  }
}

OpcaoEntregaEndereco.defaultProps = {
  className: "",
};

export default OpcaoEntregaEndereco;
